import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Widget from './Widget'

const Container = styled.div`
  border-bottom: 1px solid rgb(233, 233, 233);
  padding: 20px 15px;

  &:first-child {
    border-top: 1px solid rgb(233, 233, 233);
  }
`

const YearLabel = styled.h3`
  margin: 0 0 10px 5px;
`

const MonthsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const MonthLinkWrapper = styled.div`
  text-align: center;
  width: 25%;
`

const Archives = () => (
  <StaticQuery
    query={graphql`
      query {
        allArchivesData {
          year
          months {
            path
            month
          }
        }
      }
    `}
    render={data => {
      return (
        <ul>
          {data.allArchivesData.map((archiveYear) => {
            return (
              <Container key={archiveYear.year}>
                <YearLabel>
                  {`${archiveYear.year}年`}
                </YearLabel>
                <MonthsContainer>
                  {archiveYear.months.map(({ month, path }) => {
                    return (
                      <MonthLinkWrapper key={path}>
                        <Link to={path}>
                          {`${month}月`}
                        </Link>
                      </MonthLinkWrapper>
                    )
                  })}
                </MonthsContainer>
              </Container>
            )
          })}
        </ul>
      )
    }}
  />
)

const ArchivesWidget = () => {
  return (
    <Widget title="アーカイブ">
      <Archives />
    </Widget>
  )
}

export default ArchivesWidget
