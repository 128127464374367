import React from 'react'
import Helmet from 'react-helmet'
import logo from '../img/logo.svg'

const SEO = () => {
  // GATSBY_ACTIVE_ENV for staging should be defined in hosting
  const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development'

  return (
    <Helmet title="もばらぶエンジニアブログ – リモート開発メインのソフトウェア開発企業のエンジニアブログです">
      <html lang="ja" />
      <meta name="description" content="リモート開発メインのソフトウェア開発企業のエンジニアブログです" />

      <meta property="og:type" content="website" />
      <meta property="og:title" content="もばらぶエンジニアブログ" />
      <meta property="og:description" content="リモート開発メインのソフトウェア開発企業のエンジニアブログです" />
      <meta property="og:url" content="https://engineering.mobalab.net/" />
      <meta property="og:site_name" content="もばらぶエンジニアブログ" />
      <meta property="og:image" content={logo} />
      <meta property="og:locale" content="ja_JP" />
      <meta name="twitter:creator" content="@mobalab" />
      <meta name="twitter:site" content="@mobalab" />

      {activeEnv === 'staging' && (
        <meta name="robots" content="noindex, nofollow" />
      )}
    </Helmet>
  )
}

export default SEO