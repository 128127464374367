import React from 'react'
import Archives from './Archives'
import Tags from './Tags'
import RecentPosts from './RecentPosts'
import { BannerWidget } from './Banner'
import TableOfContents from './TableOfContents'

const Sidebar = ({ isPostPage, tableOfContentsData }) => (
  <>
    <BannerWidget />
    { tableOfContentsData && (
      <TableOfContents
        data={tableOfContentsData}
        isOnSidebar
      />
    )}
    { !isPostPage && (
      <>
        <RecentPosts />
        <Tags />
        <Archives />
      </>
    )}
  </>
)

export default Sidebar
