import React from 'react'
import styled from 'styled-components'
import Archives from './Archives'
import Tags from './Tags'
import RecentPosts from './RecentPosts'
import { BannerInPost } from './Banner'

const BannerSection = styled.div`
  display: flex;
  justify-content: center;
  margin: 60px 0;
`

const BannerWrapper = styled.div`
  background-color: #F5F5F5;
  font-size: 1.1rem;
  max-width: 800px;
  padding: 30px;

  & a {
    color: #13c4a5;
    text-decoration: underline;
  }
`

const Container = styled.div`
  // border-top: 1px solid silver;
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
`

const RecentPostsWrapper = styled.div`
  flex: 1;
  min-width: 260px;
`

const TagsWrapper = styled.div`
  flex: 1;
  min-width: 260px;
`

const ArchivesWrapper = styled.div`
  flex: 1;
  min-width: 260px;
`

const PostFooter = () => {
  return (
    <>
      {/**<BannerSection>
        <BannerWrapper>
          <BannerInPost isSmall={false} />
        </BannerWrapper>
      </BannerSection>**/}
      <Container>
        <RecentPostsWrapper>
          <RecentPosts />
        </RecentPostsWrapper>
        <TagsWrapper>
          <Tags />
        </TagsWrapper>
        <ArchivesWrapper>
          <Archives />
        </ArchivesWrapper>
      </Container>
    </>
  )
}

export default PostFooter
