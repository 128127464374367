import React from 'react'
import Widget from './Widget'

const Banner = ({ isSmall }) => {

  let currentPath = ''
  if (typeof window !== 'undefined') {
    currentPath = window.location.pathname
  }

  let showSidebarBanner = true
  if (typeof window !== 'undefined') {
    showSidebarBanner = window.innerWidth > 767
    || currentPath === '/'
    || currentPath.startsWith('/pages/')
    || currentPath.startsWith('/archives/')
  }
  
  return (
    <>
      { isSmall && showSidebarBanner && (
        <a
          className="banner__image-wrapper"
          href="https://moba-pro.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="banner__image"
            src="/img/moba-pro.png"
            alt="Moba Pro"
          />
        </a>
      )}
      { !isSmall && (
        <>
          <a
            className="banner__image-wrapper"
            href="https://moba-pro.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="banner__image banner__image--medium"
              src="/img/moba-pro.png"
              alt="we are hiring"
            />
          </a>
        </>
      )}
    </>
  )
}

export const BannerWidget = () => {
  return (
    <Widget>
      <Banner isSmall />
    </Widget>
  )
}

export const BannerInPost = () => (
  <Banner />
)
